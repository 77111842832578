/* eslint-disable jsx-a11y/anchor-has-content */
import { NavbarDesktop, IconSizeType } from '~/components/Navbar'
import { buildClassName } from '@artnetworldwide/ui-library/utils'
import { NavLinkMap } from '../global/types'

export function AuctionsHeaderDesktop({ data }: { data: NavLinkMap }) {
    const navbarProps = {
        links: data,
        maxWidthInRems: 82,
        wrapperDivClassList:
            'py-3 text-neutral-500 text-[.9375rem] leading-4 bg-white dark:bg-black',
        linkClassList:
            'items-center pr-[1.125rem] hover:text-neutral-500 focus:text-neutral-500',
        iconSize: 'small' as IconSizeType,
        nestedlinkClassList: 'text-brand-black',
    }
    return (
        // 'relative' is important here to maintain correct hover behavior during scrolling
        <div
            className={`${buildClassName(
                'AuctionsHeaderDesktop'
            )} relative mx-auto px-8`}
        >
            <NavbarDesktop {...navbarProps} />
        </div>
    )
}
