import {
    NavbarMobile,
    NavbarMobileProps,
} from '~/components/Navbar/NavbarMobile'
import { useServerTemplateParamGetter } from '~/syndicated-components/hooks'
import { NavLinkMap } from '../global/types'

export function AuctionsHeaderMobile({ data }: { data: NavLinkMap }) {
    const SYNDICATED_COMPONENT_ID = 'global-and-auctions-header'

    const getServerTemplateParam = useServerTemplateParamGetter(
        SYNDICATED_COMPONENT_ID,
        {}
    )

    const theme = getServerTemplateParam('theme')

    const navProps: NavbarMobileProps = {
        menuTitle: 'Auctions',
        links: data,
        theme,
        analyticsClassName: 'AuctionsHeaderMobileMenu',
    }

    return (
        <header>
            <NavbarMobile {...navProps} />
        </header>
    )
}
