import { initComponentInBrowser } from '~/syndicated-components/utils'
import GlobalHeaderDeliveryPackage from '../global-header/delivery-package'
import { AuctionsHeaderDesktop } from '~/components/headers/auctions/AuctionsHeaderDesktop'
import { AuctionsHeaderMobile } from '~/components/headers/auctions/AuctionsHeaderMobile'
import { NavLinkMap } from '~/components/headers/global/types'

const SYNDICATED_COMPONENT_ID = 'global-and-auctions-header'

export { serverTemplateParams, scriptOnlyParams } from '../global-header'

export { loader } from './loader.server'

export default function DeliveryPackage({
    loaderData,
}: {
    loaderData: NavLinkMap
}) {
    return (
        <GlobalHeaderDeliveryPackage
            syndicatedComponentId={SYNDICATED_COMPONENT_ID}
            renderSubheaderDesktop={() => (
                <AuctionsHeaderDesktop data={loaderData} />
            )}
            renderSubheaderMobile={() => (
                <AuctionsHeaderMobile data={loaderData} />
            )}
        />
    )
}

initComponentInBrowser(DeliveryPackage, SYNDICATED_COMPONENT_ID)
